<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card>
          <b-card-title
            ><h2>{{ $t("ProductForm.Edit_product") }}</h2>
          </b-card-title>
          <hr />
          <!-- <br /> -->
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="Edit3Icon" />
                <span>{{ $t("dashboard.content") }}</span>
              </template>
              <br />
              <b-row>
                <!--  name-->
                <b-col md="6">
                  <b-form-group>
                    <label class="required"
                      >{{ $t("ProductForm.Product_Name") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="required"
                    >
                      <b-form-input
                        v-model="productProfile.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.Product_Name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Product belongs to"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" label-for="owner_type"
                        >{{ $t("ProductForm.Product_belongs") }}
                      </label>
                      <v-select
                        id="State"
                        v-model="productProfile.typeofOwner"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProductProfile.OwnerTypeOptionArabic
                            : createProductProfile.OwnerTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Production country"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="Country" class="required"
                        >{{ $t("ProductForm.Production_country") }}
                      </label>
                      <v-select
                        id="Country"
                        v-model="productProfile.profileCountry"
                        :options="countries"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="
                          retreiveStates(productProfile.profileCountry.value)
                        "
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Production state"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="State" class="required"
                        >{{ $t("ProductForm.Production_state") }}
                      </label>

                      <v-select
                        id="State"
                        v-model="productProfile.profileState"
                        :options="statesOptions"
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Production city"
                      rules="required"
                    >
                      <label label-for="City" class="required"
                        >{{ $t("ProductForm.Production_city") }}
                      </label>
                      <b-form-input
                        id="City"
                        v-model="productProfile.profileCity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.Production_city')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Brief overview"
                      :rules="`required|max:${$maxHighlightsProfile}`"
                    >
                      <label label-for="highlights" class="required"
                        >{{ $t("ProductForm.overview_your_product") }}
                      </label>

                      <b-form-textarea
                        id="highlights"
                        v-model="productProfile.highlights"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.overview_your_product')"
                        rows="2"
                        max-rows="2"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Detailed producer description"
                      rules="required"
                    >
                      <label label-for="Owner-Details" class="required"
                        >{{ $t("ProductForm.producer_description") }}
                      </label>
                      <b-form-textarea
                        id="Owner-Details"
                        v-model="productProfile.detailsofOwner"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.producer_description')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <label label-for="description" class="required"
                        >{{ $t("ProductForm.description_your_product") }}
                      </label>
                      <b-form-textarea
                        id="description"
                        v-model="productProfile.description"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="
                          $t('ProductForm.description_your_product')
                        "
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Industries"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="Industry" class="required"
                        >{{ $t("ProductForm.industries_belong") }}
                      </label>

                      <div id="app">
                        <treeselect
                          v-model="productProfile.profileIndustries"
                          :multiple="true"
                          :placeholder="$t('common.Select')"
                          :options="industries"
                          @input="handeTreeSelect"
                          :normalizer="
                            $store.state.locale.locale == 'ar'
                              ? normalizerArabic
                              : normalizer
                          "
                        />
                      </div>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="Category" class="required"
                        >{{ $t("ProductForm.needs_from_sharing") }}
                      </label>
                      <v-select
                        id="Category"
                        v-model="productProfile.category"
                        :options="categories"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="resetDataSaleType()"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  v-if="
                    productProfile.category.value == '62b40b86956d948f90a39418'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sale type"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="saleType" class="required"
                        >{{ $t("ProductForm.sell_your_product") }}
                      </label>

                      <v-select
                        id="saleType"
                        v-model="productProfile.saleType"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProductProfile.SaleTypeOptionArabic
                            : createProductProfile.SaleTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="
                    productProfile.saleType != undefined &&
                    productProfile.saleType.value == '1' &&
                    productProfile.category.value == '62b40b86956d948f90a39418'
                  "
                  md="6"
                >
                  <b-row>
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Currency"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label label-for="" class="required"
                            >{{ $t("ProductForm.Currency") }}
                          </label>

                          <v-select
                            id=""
                            v-model="productProfile.currencyId"
                            :options="currencies"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="8">
                      <validation-provider
                        #default="{ errors }"
                        name="Price"
                        rules="required|integer"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label label-for="Price" class="required"
                            >{{ $t("ProductForm.Price") }}
                          </label>
                          <b-form-input
                            id="Price"
                            v-model="productProfile.priceNumber"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('ProductForm.Your_Own_Price')"
                            type="number"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="quantity"
                    rules="integer"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="quantity" class="required"
                        >{{ $t("ProductForm.quantity") }}
                      </label>

                      <b-form-input
                        id="quantity"
                        v-model="productProfile.productQuantity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.quantity')"
                        type="number"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="sales numbers"
                    rules="integer"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="sales_numbers" class="required"
                        >{{ $t("ProductForm.How_many_sold") }}
                      </label>

                      <b-form-input
                        id="sales_numbers"
                        v-model="productProfile.productSales"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.How_many_sold')"
                        type="number"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <label for="example-datepicker" class=""
                    >{{ $t("ProductForm.expiration_date") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules=""
                    name="Expiry Date"
                  >
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="productProfile.productExpiryDate"
                      :state="errors.length > 0 ? false : null"
                      class="mb-2"
                      :min="createProductProfile.minDate"
                      :locale="$store.state.locale.locale"
                      :placeholder="$t('common.No_date_selected')"
                      v-bind="
                        $store.state.locale.locale == 'ar' ? labels['ar'] : {}
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Product Regestration"
                    rules=""
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="regestration" class=""
                        >{{ $t("ProductForm.registration_number") }}
                      </label>
                      <b-form-input
                        id="regestration"
                        v-model="productProfile.productRegistration"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('ProductForm.registration_number')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="blockchain exchange"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label label-for="blockchain_exchange" class="required"
                        >{{ $t("ProductForm.blockchain_exchange") }}
                      </label>
                      <v-select
                        id="blockchain_exchange"
                        v-model="productProfile.blockchainExchange"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createProductProfile.ExchangeOptionArabic
                            : createProductProfile.ExchangeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProductForm.Facebook')"
                    label-for="facebook"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook"
                      rules="url"
                    >
                      <b-form-input
                        id="facebook"
                        v-model="productProfile.facebook"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://facebook.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProductForm.Instagram')"
                    label-for="twitter"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Twitter"
                      rules="url"
                    >
                      <b-form-input
                        id="twitter"
                        v-model="productProfile.twitter"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://Instagram.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProductForm.LinkedIn')"
                    label-for="linked-in"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="LinkedIn"
                      rules="url"
                    >
                      <b-form-input
                        id="linked-in"
                        v-model="productProfile.linkedIn"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://linkedin.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('ProductForm.Website')"
                    label-for="Other"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other"
                      rules="url"
                    >
                      <b-form-input
                        id="Other"
                        v-model="productProfile.otherURLS"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://other.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-icon"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-icon">{{
                        $t("ProductForm.main_product")
                      }}</label>
                      <b-form-file
                        v-model="createProductProfile.icon"
                        :state="errors.length > 0 ? false : null"
                        accept="image/jpeg, image/png, image/gif"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-images"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-images">{{
                        $t("ProductForm.product_images")
                      }}</label>
                      <b-form-file
                        v-model="createProductProfile.images"
                        accept="image/jpeg, image/png, image/gif"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                        :multiple="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-documents"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-documents"
                        >{{ $t("ProductForm.product_documents") }}
                      </label>
                      <b-form-file
                        v-model="createProductProfile.docouments"
                        accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        required
                        :state="errors.length > 0 ? false : null"
                        multiple
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="">
                      <b-form-checkbox
                        id="isSold"
                        v-model="productProfile.isSold"
                        name="isSold"
                      >
                        {{ $t("ProductForm.isSold") }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit button -->
                <b-col cols="12">
                  <b-alert
                    v-if="errorMessage"
                    show
                    variant="warning"
                    class="warning"
                    style="color: red"
                    >{{ errorMessage }}</b-alert
                  >
                  <br />
                  <b-button
                    variant="primary"
                    type="Edit"
                    @click.prevent="validationForm"
                    class="mb-2"
                  >
                    {{ $t("common.Edit") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="FileIcon" />
                <span>{{ $t("dashboard.files") }}</span>
              </template>
              <br />
              <b-row>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    :items="productProfile.files"
                    :fields="
                      $store.state.locale.locale == 'ar' ? fieldsArabic : fields
                    "
                  >
                    <template #cell(assets)="data">
                      <div v-viewer class="images">
                        <b-avatar
                          v-if="data.item.type.includes('image')"
                          variant="primary"
                          :src="data.item.fileUrl"
                          text="BV"
                        />
                      </div>
                      <a
                        v-if="data.item.type.includes('application')"
                        :href="data.item.fileUrl"
                        target="_blank"
                        >{{ $t("dashboard.Preview") }}</a
                      >
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        small
                        variant="danger"
                        @click="deleteFileById(data.item._id)"
                      >
                        {{ $t("dashboard.Delete") }}
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import axios from "axios";

import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vue2Dropzone from "vue2-dropzone";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BPopover,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BFormFile,
  BTable,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {
  required,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BFormFile,
    BTable,
    BAvatar,
    VueViewer,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    ToastificationContent,
    BAlert,
    BOverlay,
    BFormCheckbox,
    BTabs,
    BTab,
  },
  data() {
    const url = window.location.href;
    const profileId = url.split("/").slice(-1)[0];

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const minDate = new Date(today);
    // maxDate.setMonth(maxDate.getDay() + 1);
    // maxDate.setDate(15);
    return {
      show: false,
      boxTwo: "",
      locales: [
        { value: "en", text: "English US (en-US)" },
        { value: "ar", text: "Arabic Egyptian (ar-EG)" },
      ],

      labels: {
        ar: {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "لم يتم اختيار تاريخ",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ",
        },
      },

      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "assets",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      fieldsArabic: [
        {
          label: "اسم الملف",
          key: "name",
          sortable: true,
        },
        {
          label: "الملف",
          key: "assets",
          sortable: false,
        },
        {
          label: "الإجراء",
          key: "actions",
          sortable: false,
        },
      ],
      profileId: profileId,
      errorMessage: "",
      // isValidNumber: null,
      productProfile: {
        name: "",
        typeofOwner: "",
        description: "",
        highlights: "",
        detailsofOwner: "",
        userId: {
          username: "",
        },
        address: {
          profileCity: "",
          profileState: "",
          profileCountry: {
            name: "",
          },
        },
        socialMedia: {
          facebook: "",
          linkedIn: "",
          twitter: "",
          otherURLS: "",
        },
        blockchainExchange: "",
        price: {
          priceNumber: "",
          currencyId: {
            name: "",
          },
        },
        category: {
          name: "",
        },
        saleType: "",
        profileIndustries: [],
        rating: "4",
        totalComments: "",
        product: {
          productQuantity: "",
          productSales: "",
        },
        isSold: false,
      },
      dropzoneOptionsIcon: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsImage: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsDocouments: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      createProductProfile: {
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        selectedCountry: null,
        selectedIndustry: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        quantity: "",
        salesNumbers: "",
        expiryDate: "",
        productRegistration: "",
        minDate: minDate,
        icon: null,
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: "",
        // phoneCode: "",
        // email: "",
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],

        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],

        StatusOption: [
          { value: "1", text: "Complete" },
          { value: "2", text: "Incomplete" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getProductCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
      // productProfile: "profile/getProductProfile",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      //createProduct: "profile/createProduct",
      // createProduct: "profile/createProduct",
      // retreiveProductProfile: "profile/retreiveProductProfile",

      editProduct: "profile/editProduct",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
      deleteFile: "profile/deleteFile",
    }),

    resetDataSaleType() {
      // this.productProfile.saleType = "";
      this.productProfile.currencyId = "";
      this.productProfile.priceNumber = "";
    },

    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.productProfile.profileIndustries.length = 6;
      }
    },

    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const profileData = new FormData();
          // const profileProductData = {};
          // profileProductData.profileId = this.productProfile._id;
          profileData.append("profileId", this.productProfile._id);
          profileData.append("name", this.productProfile.name);
          profileData.append(
            "typeofOwner",
            this.productProfile.typeofOwner.value
          );
          profileData.append(
            "profileCountry",
            this.productProfile.profileCountry.value
          );
          profileData.append(
            "profileState",
            this.productProfile.profileState.value
          );
          profileData.append("profileCity", this.productProfile.profileCity);

          profileData.append("description", this.productProfile.description);
          profileData.append(
            "detailsofOwner",
            this.productProfile.detailsofOwner
          );
          profileData.append("highlights", this.productProfile.highlights);

          profileData.append(
            "profileIndustries",
            JSON.stringify(this.productProfile.profileIndustries)
          );
          if (this.productProfile.facebook) {
            profileData.append("facebook", this.productProfile.facebook);
          }
          if (this.productProfile.twitter) {
            profileData.append("twitter", this.productProfile.twitter);
          }
          if (this.productProfile.linkedIn) {
            profileData.append("linkedIn", this.productProfile.linkedIn);
          }
          if (this.productProfile.otherURLS) {
            profileData.append("otherURLS", this.productProfile.otherURLS);
          }
          profileData.append(
            "blockchainExchange",
            this.productProfile.blockchainExchange.value
          );
          profileData.append("category", this.productProfile.category.value);

          if (
            this.productProfile.saleType &&
            this.productProfile.category.value == "62b40b86956d948f90a39418"
          ) {
            profileData.append("saleType", this.productProfile.saleType.value);

            if (this.productProfile.saleType.value == "1") {
              profileData.append(
                "currencyId",
                this.productProfile.currencyId.value
              );
              profileData.append(
                "priceNumber",
                this.productProfile.priceNumber
              );
            }
          }

          profileData.append(
            "productQuantity",
            this.productProfile.productQuantity
          );
          profileData.append("productSales", this.productProfile.productSales);

          if (this.productProfile.productExpiryDate) {
            profileData.append(
              "productExpiryDate",
              this.productProfile.productExpiryDate
            );
          }
          if (this.productProfile.productRegistration) {
            profileData.append(
              "productRegistration",
              this.productProfile.productRegistration
            );
          }

          profileData.append("isSold", this.productProfile.isSold);

          if (this.createProductProfile.icon) {
            profileData.append("profileIcon", this.createProductProfile.icon);
          }
          if (this.createProductProfile.images) {
            for (const i of Object.keys(this.createProductProfile.images)) {
              profileData.append(
                "profileImages",
                this.createProductProfile.images[i]
              );
            }
          }
          if (this.createProductProfile.docouments) {
            for (const i of Object.keys(this.createProductProfile.docouments)) {
              profileData.append(
                "profileDocuments",
                this.createProductProfile.docouments[i]
              );
            }
          }

          profileData.append("profileType", "product");
          // eslint-disable-next-line no-underscore-dangle
          profileData.append("userId", this.user._id);
          // profileProductData.profileDataForm = profileData;
          this.editProduct(profileData)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Modified_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.show = false;
              this.$router.push({ name: "product-listAll" });
            })
            .catch((error) => {
              this.show = false;
              this.errorMessage = error.response.data.error.message;
            });
        }
      });
    },

    getProductProfile() {
      // formatProductProfile._id = state.productProfile._id
      //   ? state.productProfile._id
      //   : "";
      // formatProductProfile.name = state.productProfile.name
      //   ? state.productProfile.name
      //   : "";
      this.productProfile.typeofOwner = {
        value:
          this.productProfile.typeofOwner == "individual"
            ? "individual"
            : "group",
        text:
          this.productProfile.typeofOwner == "individual"
            ? this.$store.state.locale.locale == "ar"
              ? "فرد"
              : "Individual"
            : this.$store.state.locale.locale == "ar"
            ? "مجموعة"
            : "Group",
      };
      if (this.productProfile.address.profileCountry._id) {
        this.productProfile.profileCountry = {
          value: this.productProfile.address.profileCountry._id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.productProfile.address.profileCountry.arabicName
              : this.productProfile.address.profileCountry.text,
        };

        this.productProfile.profileState = JSON.parse(
          this.productProfile.address.profileState
        );
        this.productProfile.profileState = {
          value: this.productProfile.profileState.id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.productProfile.profileState.arabicName != undefined
                ? this.productProfile.profileState.arabicName
                : this.productProfile.profileState.name
              : this.productProfile.profileState.name,
        };

        this.productProfile.profileCity = this.productProfile.address
          .profileCity
          ? this.productProfile.address.profileCity
          : "";
      } else {
        this.productProfile.profileCountry = {
          value: "",
          text: "",
        };
      }

      this.productProfile.category = {
        value: this.productProfile.category.value,
        text:
          this.$store.state.locale.locale == "ar"
            ? this.productProfile.category.arabicName
            : this.productProfile.category.text,
      };

      // formatProductProfile.highlights = state.productProfile.highlights
      //   ? state.productProfile.highlights
      //   : "";
      // formatProductProfile.detailsofOwner = state.productProfile.detailsofOwner
      //   ? state.productProfile.detailsofOwner
      //   : "";
      // formatProductProfile.description = state.productProfile.description
      //   ? state.productProfile.description
      //   : "";

      if (this.productProfile.socialMedia) {
        this.productProfile.facebook = this.productProfile.socialMedia.facebook;

        this.productProfile.linkedIn = this.productProfile.socialMedia.linkedIn;

        this.productProfile.twitter = this.productProfile.socialMedia.twitter;

        this.productProfile.otherURLS =
          this.productProfile.socialMedia.otherURLS;
      }

      this.productProfile.blockchainExchange = {
        value:
          this.productProfile.blockchainExchange == true ? "true" : "false",
        text:
          this.productProfile.blockchainExchange == true
            ? this.$store.state.locale.locale == "ar"
              ? "نعم"
              : "Yes"
            : this.$store.state.locale.locale == "ar"
            ? "لا"
            : "No",
      };

      this.productProfile.productExpiryDate =
        this.productProfile.product.productExpiryDate;
      this.productProfile.productRegistration =
        this.productProfile.product.productRegistration;

      this.productProfile.productQuantity =
        this.productProfile.product.productQuantity;

      this.productProfile.productSales =
        this.productProfile.product.productSales;

      if (this.productProfile.saleType) {
        if (this.productProfile.saleType == "1") {
          this.productProfile.saleType = {
            value: this.productProfile.saleType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "سعري الخاص"
                : "My Own Price",
          };
          this.productProfile.priceNumber =
            this.productProfile.price.priceNumber;

          this.productProfile.currencyId = {
            value: this.productProfile.price.currencyId._id,
            text: this.productProfile.price.currencyId.text,
          };
        } else if (this.productProfile.saleType == "2") {
          this.productProfile.saleType = {
            value: this.productProfile.saleType,
            text: this.$store.state.locale.locale == "ar" ? "مزاد" : "Auction",
          };
        } else {
          this.productProfile.saleType = {
            value: "",
            text: "",
          };
        }
      }

      // let profileIndustriesArray = [];
      // state.productProfile.profileIndustries.forEach(
      //   (profileIndustries, index) => {
      //     profileIndustriesArray.push(profileIndustries._id);
      //     // console.log(value);
      //     // console.log(index);
      //   }
      // );
      // formatProductProfile.profileIndustries = state.productProfile
      //   .profileIndustries
      //   ? profileIndustriesArray
      //   : [];

      // return formatProductProfile;
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    deleteFileById(id) {
      const deleteData = new FormData();
      deleteData.append("fileId", id);
      deleteData.append("profileId", this.productProfile._id);
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.Please_confirm_delete"), {
          title: this.$t("messages.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteFile(deleteData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("messages.Deleted_successfully"),
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.productProfile.files = this.productProfile.files.filter(
                  (data) => data._id != id
                );
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.error.message,
                    icon: "CoffeeIcon",
                    variant: "error",
                  },
                });
                this.$router.push({ name: "idea-listAll" });
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();

    // this.retreiveProductProfile(this.profileId);

    axios.get(`profiles/${this.$route.params.id}`).then((response) => {
      if (response.data.data.userId._id == this.$store.state.auth.user._id) {
        this.productProfile = response.data.data;
        this.productProfile.profileIndustries = this.pluck(
          this.productProfile.profileIndustries,
          "id"
        );
        this.getProductProfile();
      } else {
        window.location.href = "/error-404";
      }
    });
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
